import { PostRequest, successParam } from '@/api/base';

export function deleteDevice(params: object, callback: successParam) {
    PostRequest('v3/web/common/device/delete', params, callback);
}

export function resetDevice(params: object, callback: successParam) {
    PostRequest('v3/web/common/device/reset', params, callback);
}

export default null;
